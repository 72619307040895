import { SpaceProps } from 'styled-system'
import AreaDto from 'interfaces/gql/AreaDto'
import ErrorBoundary, { ErrorType } from 'components/generic/error-boundary'
import { PropsWithChildren } from 'react'
import PopularEventsSubSection from './PopularEventsSubSection'

const PopularEvents: React.FC<PropsWithChildren<PopularEventsProps>> = (
  props
) => (
  <ErrorBoundary errorType={ErrorType.EventListingRender} fallback={() => null}>
    <PopularEventsSubSection {...props} />
  </ErrorBoundary>
)

type PopularEventsProps = {
  area: AreaDto
  dateFrom?: string
  SubmitEventButton?: React.FC
  title?: string
  pt?: SpaceProps['pt']
}

export default PopularEvents
